import React from 'react'

import ScrollTo from 'src/components/ScrollTo'
import { ContentHeader } from './style'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type heroType = {
  setIsModalOpenAccount: Function;
  setDataLayer: Function;
}

const Header = ({ setDataLayer, setIsModalOpenAccount }: heroType) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const dataLayer: IDataLayerParams = {
    section: 'dobra_01',
    section_name: 'VIP Lounge Inter. Sua viagem ainda mais inesquecível.',
    element_action: 'click button',
    element_name: 'Conheça os benefícios',
  }

  return (
    <ContentHeader className='py-5 d-flex align-items-end align-items-md-center' role='img' aria-label='Sala Vip Inter no Aeroporto de Guarulhos'>
      <div className='container'>
        <div className='row justify-content-center justify-content-md-start'>
          <div className='col-12 col-md-6 col-lg-6'>
            <h1 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 fs-xl-48 lh-xl-60 text-white mt-5 pt-5 mt-md-0 pt-md-0'>
              <span className='d-block fw-700'>VIP Lounge Inter.</span>
              <span className='fw-500'>Sua viagem ainda mais inesquecível.</span>
            </h1>
            <ScrollTo
              to='#conforto-e-exclusividade'
              section='dobra_01'
              sectionName='VIP Lounge Inter. Sua viagem ainda mais inesquecível.'
              elementName='Conheça os benefícios'
              styles='btn btn-orange--extra rounded-2 fs-14 fw-600 text-none mt-3 mw-100'
            >
              Conheça os benefícios
            </ScrollTo>
          </div>
        </div>
      </div>
    </ContentHeader>
  )
}

export default Header
