import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'

export const MasterCardWithCashback = styled.section`
  background-color: ${grayscale[500]};

  img {
    object-fit: cover;
    border-radius: 16px;
  }
`

export const Button = styled.button`
  @media (min-width: ${breakpoints.md}) {
    width: 60%;
  }
`
