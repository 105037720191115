import React, { useState } from 'react'
import useDomReady from 'src/hooks/window/useDomReady'
import { Modal } from 'src/components/Modal'
import OpenAccountRightForm from 'src/components/UI/Forms/OpenAccountRightForm'

import Layout from 'src/layouts/BaseLayout'

import Header from './sections/header/_index'
import TourVirtual from './sections/tour-virtual/_index'
import ConfortoEExclusividade from './sections/conforto-e-exclusividade/_index'
import LoungeKey from './sections/lounge-key/_index'
import ComoAproveitar from './sections/como-aproveitar/_index'
import SuaVidaFinanceiraMaisInteligente from './sections/sua-vida-financeira-mais-inteligente/_index'

import pageContext from './pageContext.json'

import { Wrapper } from './style'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

function InterBlack () {
  const domReady = useDomReady()
  const [ isModalOpenAccount, setIsModalOpenAccount ] = useState(false)

  const [ dataLayer, setDataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_01',
    section_name: 'Cartão de crédito empresarial Inter sem anuidade',
    element_action: 'click button',
    element_name: 'Abrir conta MEI gratuita',
  })

  const openAccountModal = domReady && (
    <Modal isModalOpen={isModalOpenAccount} setIsModalOpen={setIsModalOpenAccount} locationToRender={document.body}>
      <OpenAccountRightForm
        closeModal={() => setIsModalOpenAccount(false)}
        dataLayer={dataLayer}
        downloadAppCustomMessage='BAIXAR APP E ABRIR CONTA AGORA'
        customOrigin='Sala Vip'
      />
    </Modal>
  )

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {openAccountModal}
        <Header setDataLayer={setDataLayer} setIsModalOpenAccount={setIsModalOpenAccount} />
        <TourVirtual />
        <ConfortoEExclusividade />
        <ComoAproveitar setDataLayer={setDataLayer} setIsModalOpenAccount={setIsModalOpenAccount} />
        <LoungeKey setDataLayer={setDataLayer} setIsModalOpenAccount={setIsModalOpenAccount} />
        <SuaVidaFinanceiraMaisInteligente setDataLayer={setDataLayer} setIsModalOpenAccount={setIsModalOpenAccount} />
      </Layout>
    </Wrapper>
  )
}

export default InterBlack
