import React from 'react'

import { Section } from './style'

type TourVirtualProps = {
  setIsModalOpenAccount: Function;
  setDataLayer: Function;
}

const TourVirtual = () => {
  return (
    <Section className=''>
      <iframe width='100%' height='100%' frameBorder='0' allow='xr-spatial-tracking; gyroscope; accelerometer' allowfullscreen scrolling='no' src='https://imdesigns.viewin360.co/InterVIPLounge' />
    </Section>
  )
}

export default TourVirtual
