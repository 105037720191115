import React from 'react'
import ImageWebp from 'src/components/ImageWebp'

import { MasterCardWithCashback, Button } from './style'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type ComoAproveitarProps = {
  setIsModalOpenAccount: Function;
  setDataLayer: Function;
}

const ComoAproveitar = ({ setDataLayer, setIsModalOpenAccount }: ComoAproveitarProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const arrayNumbers: number | string[] = [
    '100%',
  ]

  const arrayHeight: number[] = [
    300, 300, 436, 436,
  ]

  const dataLayer: IDataLayerParams = {
    section: 'dobra_03',
    section_name: 'Conforto e exclusividade pra você.',
    element_action: 'click button',
    element_name: 'Abra a sua conta',
  }

  return (
    <MasterCardWithCashback className='py-5 d-flex'>
      <div className='container'>
        <div className='row d-flex'>

          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-md-40 lh-md-50 text-white text-md-center fw-600 font-sora mb-4'>
              Como aproveitar
            </h2>
            <p className='fs-14 lh-16 fs-md-18 lh-md-22 text-white text-md-center fw-400 mb-5'>
              <span className='d-md-block'>Apresente seu cartão Inter Black e o cartão de embarque em</span> uma de nossas unidades até 4 horas antes do seu voo.
            </p>
          </div>

          <div className='col-12 col-lg-6'>
            <div className=''>
              <ImageWebp
                pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/sala-vip-lounge/image.webp'
                altDescription='Área de acesso ao Vip Lounge Inter'
                arrayNumbers={arrayNumbers}
                arrayNumbersHeight={arrayHeight}
              />
            </div>

            <p className='fs-20 lh-25 fs-xl-24 lh-xl-28 fw-700 text-white font-citrina mt-4'>
              Traga seus investimentos para o Inter.
              Acesso ilimitado às nossas Salas VIP
            </p>

            <p className='fs-14 lh-16 text-white'>
              O Inter oferece gestão de patrimônio e especialistas em investimento para auxiliar você na construção do seu futuro.
            </p>

            <p className='fs-14 lh-16 text-white'>
              Se você tem a partir de R$ 250.000 investidos no Inter, seu acesso às VIP Lounge Inter é gratuito e ilimitado.
            </p>

            <p className='fs-14 lh-16 text-white mb-5'>
              Seja um cliente Win ou Inter Black e tenha uma experiência ainda mais exclusiva.
            </p>
          </div>

          <div className='col-12 col-lg-6'>
            <div className=''>
              <ImageWebp
                pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/sala-vip-2/image.webp'
                altDescription='Sala Vip Inter no Aeroporto de Guarulhos'
                arrayNumbers={arrayNumbers}
                arrayNumbersHeight={arrayHeight}
              />
            </div>

            <p className='fs-20 lh-25 fs-xl-24 lh-xl-28 text-white fw-700 font-citrina mt-4'>
              Clientes Cartão Black Promocional
            </p>

            <p className='fs-14 lh-16 text-white'>
              Se você adquiriu um cartão Black por:
            </p>

            <p className='fs-14 lh-16 text-white'>
              - Campanhas promocionais <br />
              - Promoções exclusivas de assinatura anual Inter Duo Gourmet <br />
              - Gastos na fatura
            </p>

            <p className='fs-14 lh-16 text-white mb-4 mb-md-5'>
              Seu acesso gratuito às VIP Lounge Inter são limitados a seis vezes ao ano.
              Após esse limite, consulte a disponibilidade da compra de acesso às salas.
            </p>

          </div>

          <div className='col-12 mb-4'>
            <div className='d-flex justify-content-md-center'>
              <Button
                aria-label='Abra a sua conta'
                className='btn btn-orange--extra rounded-2 fs-14 fw-600 text-none mw-100'
                onClick={() => {
                  setIsModalOpenAccount(true)
                  setDataLayer(dataLayer)
                  sendDatalayerEvent(dataLayer)
                }}
              >
                Abra a sua conta
              </Button>
            </div>
          </div>

          <p className='fs-16 lh-20 fs-md-16 lh-md-20 text-white font-sora fw-600'>Acompanhantes</p>

          <p className='fs-14 lh-17 text-white'>
            Caso você tenha um acompanhante que tenha um cartão ligado à sua conta, é importante informar no momento da entrada. Não é preciso fazer reserva. Porém, algumas salas VIP podem ter tempo máximo de permanência. O valor cobrado por acompanhante também varia entre as salas, assim como o limite de idade para acesso gratuito de crianças. Confira as especificações com a equipe VIP Lounge Inter. Em caso de acompanhante não portador de um cartão Inter adicional, poderá ser cobrado o valor de US$ 35 por acesso para cada pessoa.
          </p>

        </div>
      </div>
    </MasterCardWithCashback>
  )
}

export default ComoAproveitar
