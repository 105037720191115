import React from 'react'

import { HowToBeInterBlack } from './style'

import ConfortoEExclusividadeJSON from '../../assets/data/conforto-e-exclusividade.json'
import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

interface IConfortoEExclusividade {
  icon: string;
  description: string;
}

type ConfortoEExclusividadeProps = {
  setIsModalOpenAccount: Function;
  setDataLayer: Function;
}

const ConfortoEExclusividade = () => {
  return (
    <HowToBeInterBlack id='conforto-e-exclusividade' className='py-5 d-flex align-items-end align-items-md-start align-items-lg-center' role='img' aria-label='Sala Vip Inter no Aeroporto de Guarulhos'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-7 offset-md-5 col-lg-6 offset-lg-6'>
            <h2 className='fs-28 lh-32 fs-md-40 lh-md-44 fs-lg-48 lh-lg-52 text-white font-citrina fw-500'>
              Conforto e <span className='d-block d-xl-inline'>exclusividade</span> pra você.
            </h2>

            <ul className='row mb-0'>
              {ConfortoEExclusividadeJSON.map((item: IConfortoEExclusividade, index: number) => (
                <li className='col-12 col-lg-12 mb-3' key={index}>
                  <div className='d-flex align-items-center icon'>
                    <div className='mr-4'>
                      <OrangeDsIcon size='MD' color='#FFFFFF' icon={item.icon} />
                    </div>
                    <h3 className='fs-10 lh-12 fs-md-14 lh-md-17 fs-xl-18 lh-xl-22 text-white fw-400 mb-0'>
                      {item.description}
                    </h3>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </HowToBeInterBlack>
  )
}

export default ConfortoEExclusividade
